// SPDX-FileCopyrightText: 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

/*! This Executable Form is subject to Mozilla Public License, v. 2.0.
 *! The Source Code Form is available at https://gitlab.com/ahayzen/stringer-builders.co.uk
 *! Copyright 2020 Andrew Hayzen <ahayzen@gmail.com> */

function hideImageGridModal(): void {
    let elm: HTMLElement | null = document.getElementById("image-modal");
    if (elm && elm.parentNode) {
        elm.parentNode.removeChild(elm);
    }
}

function showImageGridModal(this: HTMLAnchorElement, event: Event): boolean {
    // We ignore modal images on mobile (they can pinch to zoom instead)
    if (!window.matchMedia("(max-width: 1000px)").matches) {
        event.preventDefault();
        hideImageGridModal();

        document.body.insertAdjacentHTML("beforeend", "<figure id=\"image-modal\" onclick=\"hideImageGridModal();\"><img alt=\"" + this.dataset.imageName + "\" src=\"" + this.href + "\" /><figcaption>" + this.dataset.imageName + "</figcaption></figure>");
    }

    return false;
}

window.addEventListener("load", function () {
    // Find all anchors that are using image datasets
    for (let anchor of document.querySelectorAll<HTMLAnchorElement>("a[data-bind='show-image-modal']")) {
        // Connect the click event to show the image grid modal
        anchor.addEventListener("click", showImageGridModal.bind(anchor));
    }
});
